import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-answer-order',
  templateUrl: './answer-price.component.html',
  styleUrls: ['./answer-price.component.sass']
})
export class AnswerPriceComponent implements OnInit {
  confirmId: any;
  addPriceForm: FormGroup;

  constructor(
      private orderService: OrderService,
      private formBuilder: FormBuilder,
  ) {
    this.orderService.confirmAnswer.subscribe(cnt => this.confirmId = cnt);
  }

  ngOnInit() {
    this.addPriceForm = this.formBuilder.group({
      text: ['', Validators.required]
    });
  }

  close() {
    this.orderService.answerText(null);
    this.confirmId = null;
    this.addPriceForm.controls['text'].setValue('');
  }

  get f() { return this.addPriceForm.controls; }

  confirm() {
    this.confirmId.text = this.f.text.value;
    this.orderService.answerOrderToDo(this.confirmId).subscribe(data => {
      this.close();
    });
  }

  onSubmit() {

    if (!this.f.text.value) {
      alert('Вкажіть коментар!');
      return;
    }
    this.confirm();
  }




}
