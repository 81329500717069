import {Injectable, EventEmitter} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import domain from './domain.config';

@Injectable({
    providedIn: 'root'
})
export class OrderService {

    constructor(private _http: HttpClient) {
    }
    private orderId: any;
    private orderIdNotification: any;
    private markId: any;
    private specId: any;
    private specIdReturn: any;
    private newsId: any;
    private messageId: any;
    private userId: any;
    private userChangeId: any;
    private closeId: any;
    private messageIdSingle: any;
    private confirmId: any;
    private answerId: any;
    private confirmIdOrder: any;
    private addFileOrder: any;
    private paymentIdOrder: any;
    private penaltyIdOrder: any;
    private userIdOrder: any;
    private userIdOrderPenalty: any;
    private toworkIdOrder: any;
    private statusIdUser: any;
    private sendIdUser: any;
    private sendIdUserEmail: any;
    private statusNotificatorId: any;
    private statusNotificatorIdAdmin: any;
    private statusNotificatorIdPrice: any;
    private statusNotificatorIdPriceManager: any;
    private messageAdmin: any;
    private statusOrderNotificatorId: any;
    private statusExpire: any;
    private statusNewsId: any;
    private confirmOrderUsers: any;
    private confirmMarkerUsers: any;
    private orderStatus: any;
    private editOrderDate: any;
    private removeOrderDate: any;
    private confirmReview: any;
    private dateEndOrder: any;
    private newsPopupUser: any;
    private elementDone: any;
    private checkmess: any;
    private removePriceId: any;
    private calendarChange: any;
    onClick: EventEmitter<any> = new EventEmitter();
    onNotification: EventEmitter<any> = new EventEmitter();
    onMark: EventEmitter<any> = new EventEmitter();
    onRemoveSpec: EventEmitter<any> = new EventEmitter();
    onRemoveSpecReturn: EventEmitter<any> = new EventEmitter();
    onClickCalendar: EventEmitter<any> = new EventEmitter();
    onClickRemovePrice: EventEmitter<any> = new EventEmitter();
    removeClick: EventEmitter<any> = new EventEmitter();
    confirmOrder: EventEmitter<any> = new EventEmitter();
    confirmAnswer: EventEmitter<any> = new EventEmitter();
    confirmOrderPrice: EventEmitter<any> = new EventEmitter();
    toworkOrderPrice: EventEmitter<any> = new EventEmitter();
    paymentOrderPrice: EventEmitter<any> = new EventEmitter();
    penaltyOrderPrice: EventEmitter<any> = new EventEmitter();
    dateEnd: EventEmitter<any> = new EventEmitter();
    sendMessStatus: EventEmitter<any> = new EventEmitter();
    sendMessAdmin: EventEmitter<any> = new EventEmitter();
    sendMessStatusEmail: EventEmitter<any> = new EventEmitter();
    confirmStatus: EventEmitter<any> = new EventEmitter();
    confirmNotification: EventEmitter<any> = new EventEmitter();
    confirmNotificationAdmin: EventEmitter<any> = new EventEmitter();
    confirmNotificationPrice: EventEmitter<any> = new EventEmitter();
    confirmNotificationPriceManager: EventEmitter<any> = new EventEmitter();
    confirmMessAdmin: EventEmitter<any> = new EventEmitter();
    orderNotification: EventEmitter<any> = new EventEmitter();
    confirmExpire: EventEmitter<any> = new EventEmitter();
    confirmNews: EventEmitter<any> = new EventEmitter();
    confirmAddStatus: EventEmitter<any> = new EventEmitter();
    confirmChangeMarker: EventEmitter<any> = new EventEmitter();
    confirmChangeStatus: EventEmitter<any> = new EventEmitter();
    confirmEditCloseDate: EventEmitter<any> = new EventEmitter();
    confirmRemoveCloseDate: EventEmitter<any> = new EventEmitter();
    confirmAddReview: EventEmitter<any> = new EventEmitter();
    onClickMessageSingle: EventEmitter<any> = new EventEmitter();
    onClickMessage: EventEmitter<any> = new EventEmitter();
    onClickUser: EventEmitter<any> = new EventEmitter();
    onClickClose: EventEmitter<any> = new EventEmitter();
    checkMessage: EventEmitter<any> = new EventEmitter();
    addFile: EventEmitter<any> = new EventEmitter();
    onChangeUser: EventEmitter<any> = new EventEmitter();
    confirmOpenLoadMessage: EventEmitter<any> = new EventEmitter();
    userOrderPrice: EventEmitter<any> = new EventEmitter();
    userOrderPenalty: EventEmitter<any> = new EventEmitter();
    confirmDoneWork: EventEmitter<any> = new EventEmitter();

    public doneWork(element, id, id_order) {
        this.elementDone = {element: element, id: id, id_order: id_order};
        this.confirmDoneWork.emit(this.elementDone);
    }

    public removeOrder(id) {
        this.orderId = id;
        this.onClick.emit(this.orderId);
    }

    public notificationOrder(id) {
        this.orderIdNotification = id;
        this.onNotification.emit(this.orderIdNotification);
    }

    public removeMark(id) {
        this.markId = id;
        this.onMark.emit(this.markId);
    }

    public removeSpec(id) {
        this.specId = id;
        this.onRemoveSpec.emit(this.specId);
    }

    public removeSpecReturn(id) {
        this.specIdReturn = id;
        this.onRemoveSpecReturn.emit(this.specIdReturn);
    }

    public calendarOpen(id) {
        this.calendarChange = id;
        this.onClickCalendar.emit(this.calendarChange);
    }

    public removePrice(id) {
        this.removePriceId = id;
        this.onClickRemovePrice.emit(this.removePriceId);
    }

    public userMessagePopup(id_user, id_message) {
        this.newsPopupUser = {id_user: id_user, id_message: id_message};
        this.confirmOpenLoadMessage.emit(this.newsPopupUser);
    }

    public changeTypeUser(id) {
        this.userChangeId = id;
        this.onChangeUser.emit(this.userChangeId);
    }

    public removeNews(id) {
        this.newsId = id;
        this.removeClick.emit(this.newsId);
    }

    public removeMessage(id) {
        this.messageId = id;
        this.onClickMessage.emit(this.messageId);
    }

    public removeUser(id) {
        this.userId = id;
        this.onClickUser.emit(this.userId);
    }

    public closeOrder(id) {
        this.closeId = id;
        this.onClickClose.emit(this.closeId);
    }

    public removeMessageSingle(id) {
        this.messageIdSingle = id;
        this.onClickMessageSingle.emit(this.messageIdSingle);
    }

    public orderConfirm(id) {
        this.confirmId = id;
        this.confirmOrder.emit(this.confirmId);
    }

    public answerText(id) {
        this.answerId = id;
        this.confirmAnswer.emit({id: this.answerId});
    }

    public orderPrice(id, id_user) {
        this.confirmIdOrder = {id : id, id_user: id_user};
        this.confirmOrderPrice.emit(this.confirmIdOrder);
    }

    public orderAddFile(id, id_user, type) {
        this.addFileOrder = {id : id, id_user: id_user, title: type};
        this.addFile.emit(this.addFileOrder);
    }

    public paymentPrice(id, id_user) {
        this.paymentIdOrder = {id : id, id_user: id_user};
        this.paymentOrderPrice.emit(this.paymentIdOrder);
    }

    public penaltyPrice(id, id_user) {
        this.penaltyIdOrder = {id : id, id_user: id_user};
        this.penaltyOrderPrice.emit(this.penaltyIdOrder);
    }

    public dateEndFunc(id, value) {
        this.dateEndOrder = {id : id, date: value};
        this.dateEnd.emit(this.dateEndOrder);
    }

    public priceUserOrder(id, id_user) {
        this.userIdOrder = {id : id, id_user: id_user};
        this.userOrderPrice.emit(this.userIdOrder);
    }

    public priceUserPenalty(price, id_order) {
        this.userIdOrderPenalty = {price : price, id_order: id_order};
        this.userOrderPenalty.emit(this.userIdOrderPenalty);
    }

    public toworkOrder(id, id_user, content) {
        this.toworkIdOrder = {id : id, id_user: id_user, content: content};
        this.toworkOrderPrice.emit(this.toworkIdOrder);
    }

    public userStatus(data_start, data_end, id_user) {
        this.statusIdUser = {data_start : data_start, data_end: data_end, id_user: id_user};
        this.confirmStatus.emit(this.statusIdUser);
    }

    public userSend(comment, id_user) {
        this.sendIdUser = {comment : comment, id_user: id_user};
        this.sendMessStatus.emit(this.sendIdUser);
    }

    public adminSend(comment, id_user) {
        this.sendIdUser = {comment : comment, id_user: id_user};
        this.sendMessAdmin.emit(this.sendIdUser);
    }

    public userSendEmail(text, id_user) {
        this.sendIdUserEmail = {text : text, id_user: id_user};
        this.sendMessStatusEmail.emit(this.sendIdUserEmail);
    }

    public userNotification(id_user) {
        this.statusNotificatorId = {id_user: id_user};
        this.confirmNotification.emit(this.statusNotificatorId);
    }

    public userNotificationAdmin(id_user) {
        this.statusNotificatorIdAdmin = {id_user: id_user};
        this.confirmNotificationAdmin.emit(this.statusNotificatorIdAdmin);
    }

    public userNotificationPrice(id_user) {
        this.statusNotificatorIdPrice = {id_user: id_user};
        this.confirmNotificationPrice.emit(this.statusNotificatorIdPrice);
    }

    public userNotificationPriceManager(id_user) {
        this.statusNotificatorIdPriceManager = {id_user: id_user};
        this.confirmNotificationPriceManager.emit(this.statusNotificatorIdPriceManager);
    }

    public userMessageAdmin(id_user) {
        this.messageAdmin = {id_user: id_user};
        this.confirmMessAdmin.emit(this.messageAdmin);
    }

    public userOrderNotification(id) {
        this.statusOrderNotificatorId = {id: id};
        this.orderNotification.emit(this.statusOrderNotificatorId);
    }

    public userExpire(data) {
        this.statusExpire = {data: data};
        this.confirmExpire.emit(this.statusExpire);
    }

    public userNews(id_user) {
        this.statusNewsId = {id_user: id_user};
        this.confirmNews.emit(this.statusNewsId);
    }

    public userConfirmOrder(id_order, users) {
        this.confirmOrderUsers = {id_order : id_order, users: users};
        this.confirmAddStatus.emit(this.confirmOrderUsers);
    }

    public userChangeMarker(id_user, defaul) {
        this.confirmMarkerUsers = {id_user : id_user, default: defaul};
        this.confirmChangeMarker.emit(this.confirmMarkerUsers);
    }

    public changeStatusOrder(id_order) {
        this.orderStatus = id_order;
        this.confirmChangeStatus.emit(this.orderStatus);
    }

    public editDateClose(id_order) {
        this.editOrderDate = id_order;
        this.confirmEditCloseDate.emit(this.editOrderDate);
    }

    public removeDateClose(id_order) {
        this.removeOrderDate = id_order;
        this.confirmRemoveCloseDate.emit(this.removeOrderDate);
    }


    public userReview(id_order, users) {
        this.confirmReview = {id_order : id_order, users: users};
        this.confirmAddReview.emit(this.confirmReview);
    }

    public checkMessages(id) {
        this.checkmess = id;
        this.checkMessage.emit(this.checkmess);
    }

    removeOrderById(id) {
        return this._http.post<any>(`${domain}/user/removeOrderById`, {id});
    }

    removeMarkById(id) {
        return this._http.post<any>(`${domain}/user/removeMarkById`, {id});
    }

    removeSpecById(id) {
        return this._http.post<any>(`${domain}/user/removeSpecById`, {id});
    }

    removePriceById(id) {
        return this._http.post<any>(`${domain}/user/removePriceById`, {id});
    }

    removeDateCloseManager(id) {
        return this._http.post<any>(`${domain}/user/removeDateCloseManager`, {id});
    }


    confirmOrderById(id) {
        return this._http.post<any>(`${domain}/user/confirmOrderById`, {id});
    }

    priceOrderById(data) {
        return this._http.post<any>(`${domain}/user/priceOrderById`, {data});
    }

    notificationOrderById(data) {
        return this._http.post<any>(`${domain}/user/notificationOrderById`, {data});
    }

    answerOrderToDo(data) {
        return this._http.post<any>(`${domain}/user/answerOrderToDo`, {data});
    }

    addFileToOrder(data) {
        return this._http.post<any>(`${domain}/user/addFileToOrder`, {data});
    }

    paymentOrderById(data) {
        return this._http.post<any>(`${domain}/user/paymentOrderById`, {data});
    }

    pricerderByUser(data) {
        return this._http.post<any>(`${domain}/user/pricerderByUser`, {data});
    }

    pricerderByUserPenalty(data) {
        return this._http.post<any>(`${domain}/user/pricerderByUserPenalty`, {data});
    }

    penaltyOrderById(data) {
        return this._http.post<any>(`${domain}/user/penaltyOrderById`, {data});
    }

    changeDateClose(data) {
        return this._http.post<any>(`${domain}/user/changeDateClose`, {data});
    }

    dataEndOrderById(data) {
        return this._http.post<any>(`${domain}/user/dataEndOrderById`, {data});
    }

    todoOrderById(data) {
        return this._http.post<any>(`${domain}/user/todoOrderById`, {data});
    }

    readNotification(id) {
        return this._http.post<any>(`${domain}/user/readNotification`, {id});
    }

    readNotificationAll(id) {
        return this._http.post<any>(`${domain}/user/readNotificationAll`, {id});
    }

    readNotificationAllAdmin(id) {
        return this._http.post<any>(`${domain}/user/readNotificationAllAdmin`, {id});
    }

    readNewsById(data) {
        return this._http.post<any>(`${domain}/user/readNewsById`, {data});
    }

   sendStatus(data) {
        return this._http.post<any>(`${domain}/user/sendStatus`, {data});
    }

    sendMess(data) {
        return this._http.post<any>(`${domain}/user/sendMess`, {data});
    }

    sendAdmin(data) {
        return this._http.post<any>(`${domain}/user/sendAdmin`, {data});
    }

    sendMailUser(data) {
        return this._http.post<any>(`${domain}/user/sendMailUser`, {data});
    }

    sendReview(data) {
        return this._http.post<any>(`${domain}/user/sendReview`, {data});
    }

    sendOrderUsers(data) {
        return this._http.post<any>(`${domain}/user/sendOrderUsers`, {data});
    }

    sendMarkerUser(data) {
        return this._http.post<any>(`${domain}/user/sendMarkerUser`, {data});
    }

    changeStatus(data) {
        return this._http.post<any>(`${domain}/user/changeStatusOrder`, {data});
    }

    getMyPriceOrderById(data) {
        return this._http.post<any>(`${domain}/user/getMyPriceOrderById`, {data});
    }

    getOrderUserById(id) {
        return this._http.post<any>(`${domain}/user/getOrderUserById`, {id});
    }

    sendMessage(data) {
        return this._http.post<any>(`${domain}/user/sendMessage`, {data});
    }

    sendMessageSingle(data) {
        return this._http.post<any>(`${domain}/user/sendMessageSingle`, {data});
    }

    getAllMessage(id) {
        return this._http.post<any>(`${domain}/user/getAllMessage`, {id});
    }

    getAllMessageUser(id) {
        return this._http.post<any>(`${domain}/user/getAllMessageUser`, {id});
    }

    getAllMessageUserAccount(id) {
        return this._http.post<any>(`${domain}/user/getAllMessageUserAccount`, {id});
    }

    removeMessageById(id) {
        return this._http.post<any>(`${domain}/user/removeMessageById`, {id});
    }

    removeUserById(id) {
        return this._http.post<any>(`${domain}/user/removeUserById`, {id});
    }

    updateDateDone(data,id) {
        return this._http.post<any>(`${domain}/user/updateDateDone`, {data: data, id: id});
    }

    changeUserType(id) {
        return this._http.post<any>(`${domain}/user/changeUserType`, {id});
    }

    closeOrderById(id) {
        return this._http.post<any>(`${domain}/user/closeOrderById`, {id});
    }

    removeNewsById(id) {
        return this._http.post<any>(`${domain}/user/removeNewsById`, {id});
    }

    removeUploadById(id) {
        return this._http.post<any>(`${domain}/user/removeUploadById`, {id});
    }

    removeMessageByIdSingle(id) {
        return this._http.post<any>(`${domain}/user/removeMessageByIdSingle`, {id});
    }

    getAllMessageManager(id) {
        return this._http.post<any>(`${domain}/user/getAllMessageManager`, {id});
    }

    getAllMessageManagerNew(data) {
        return this._http.post<any>(`${domain}/user/getAllMessageManagerNew`, {data});
    }

    getAllMessageManagerNewAccount(data) {
        return this._http.post<any>(`${domain}/user/getAllMessageManagerNewAccount`, {data});
    }

    getAllMessageManagerCount(id) {
        return this._http.post<any>(`${domain}/user/getAllMessageManagerCount`, {id});
    }

    getAllMessageManagerCountAccount(id) {
        return this._http.post<any>(`${domain}/user/getAllMessageManagerCountAccount`, {id});
    }

    getAllMessageAccount(id) {
        return this._http.post<any>(`${domain}/user/getAllMessageAccount`, {id});
    }

    getAllMessageSingle(id) {
        return this._http.post<any>(`${domain}/user/getAllMessageSingle`, {id});
    }

    getAllMessageSingleAll(id) {
        return this._http.post<any>(`${domain}/user/getAllMessageSingleAll`, {id});
    }


    readMessageAll(data) {
        return this._http.post<any>(`${domain}/user/readMessageSingleId`, {data});
    }

    getMessageSingleDetails(id) {
        return this._http.post<any>(`${domain}/user/getMessageSingleDetails`, {id});
    }

    getAllNotification(id) {
        return this._http.post<any>(`${domain}/user/getAllNotification`, {id});
    }

    getReviewOrder(id) {
        return this._http.post<any>(`${domain}/user/getReviewOrder`, {id});
    }

    readMessageByIdOrder(data) {
        return this._http.post<any>(`${domain}/user/readMessageByIdOrder`, {data});
    }
}
