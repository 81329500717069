import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-price-order',
  templateUrl: './price-order.component.html',
  styleUrls: ['./price-order.component.sass']
})
export class PriceOrderComponent implements OnInit {
  confirmId: any;
  addPriceForm: FormGroup;

  constructor(
      private orderService: OrderService,
      private formBuilder: FormBuilder,
  ) {
    this.orderService.confirmOrderPrice.subscribe(cnt => this.confirmId = cnt);
  }

  ngOnInit() {
    this.addPriceForm = this.formBuilder.group({
      price: ['', Validators.required],
      text: ['']
    });
  }

  close() {
    this.orderService.orderPrice(null, null);
    this.confirmId = null;
  }

  get f() { return this.addPriceForm.controls; }

  confirm() {
    this.confirmId.price = this.f.price.value;
    this.confirmId.text = this.f.text.value;
    this.orderService.priceOrderById(this.confirmId).subscribe(data => {
      this.close();
    });
  }

  onSubmit() {

    if (!this.addPriceForm.valid || this.f.price.value == 0) {
      alert('Вкажіть ціну більше 0!');
      return;
    }
    this.confirm();
  }




}
