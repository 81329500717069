import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';


@Component({
  selector: 'app-delete-mark',
  templateUrl: './delete-mark.component.html',
  styleUrls: ['./delete-mark.component.sass']
})

export class DeleteMarkComponent implements OnInit {
  removeId: any;
  idManager: any;

  constructor(
      private orderService: OrderService,
  ) {
    this.orderService.onMark.subscribe(cnt => this.removeId = cnt);
  }

  ngOnInit() {

  }

  close() {
    this.orderService.removeMark(null);
  }

  remove() {
    if (localStorage.currentUser) {
      this.idManager = JSON.parse(localStorage.currentUser).id;
    }
    this.orderService.removeMarkById({
      id: this.removeId,
    }).subscribe(data => {
      this.close();
    });
  }

}
