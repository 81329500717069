import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';


@Component({
  selector: 'app-remove-date',
  templateUrl: './remove-date.component.html',
  styleUrls: ['./remove-date.component.sass']
})
export class RemoveDateComponent implements OnInit {
  removeId: any;

  constructor(
      private orderService: OrderService,
  ) {
    this.orderService.confirmRemoveCloseDate.subscribe(cnt => this.removeId = cnt);
  }

  ngOnInit() {

  }

  close() {
    this.orderService.removeDateClose(null);
  }

  remove() {
    this.orderService.removeDateCloseManager(this.removeId).subscribe(data => {
      this.close();
    });
  }

}
