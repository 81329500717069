import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-notification-manager',
  templateUrl: './notification-manager.component.html',
  styleUrls: ['./notification-manager.component.sass']
})
export class NotificationManagerComponent implements OnInit {
  confirmId: any;
  allNot: any;

  constructor(
      private orderService: OrderService,
      private authService: AuthService,
      private route: ActivatedRoute,
      private router: Router,
  ) {
    this.orderService.confirmNotificationPriceManager.subscribe(cnt => this.getAllNotification(cnt) );
  }

  ngOnInit() {
  }

  getAllNotification(id) {
    this.confirmId = id;
    if(this.confirmId) {
      this.authService.getAllNotPriceManager(this.confirmId).subscribe(data => {
        this.allNot = data.map(e => {
          e.penalty = e.status;
          e.date = moment(e.date).format('DD/MM/YYYY HH:mm');
          return e;
        });
      });
    }
  }

  close() {
    this.orderService.userNotificationPriceManager(null);
    this.confirmId = null;
    this.allNot = null;
  }


  readMessage(id, id_order) {
    this.orderService.readNotification(id).subscribe(data => {
      this.router.navigate(['/account/order/' + id_order]);
      this.close();
    });
  }

}
