import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-add-notification-order',
  templateUrl: './add-notification-order.component.html',
  styleUrls: ['./add-notification-order.component.sass']
})
export class AddNotificationOrderComponent implements OnInit {
  confirmId: any = {};
  idConfirm: any;
  addPriceForm: FormGroup;

  constructor(
      private orderService: OrderService,
      private formBuilder: FormBuilder,
  ) {
    this.orderService.onNotification.subscribe(cnt => this.idConfirm = cnt);
  }

  ngOnInit() {
    this.addPriceForm = this.formBuilder.group({
      text: ['']
    });
  }

  close() {
    this.orderService.notificationOrder(null);
    this.confirmId = {};
    this.idConfirm = null;
  }

  get f() { return this.addPriceForm.controls; }

  confirm() {
      this.confirmId.text = this.f.text.value;
      this.confirmId.id_order = parseInt(this.idConfirm);
      this.orderService.notificationOrderById(this.confirmId).subscribe(data => {
        this.close();
      });
  }

  onSubmit() {
    this.confirm();
  }




}
