import {Component, OnInit} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {Location} from '@angular/common';
import {DateAdapter} from '@angular/material/core';

@Component({
  selector: 'app-close-date',
  templateUrl: './close-date.component.html',
  styleUrls: ['./close-date.component.sass']
})
export class CloseDateComponent implements OnInit {
  confirmId: any;
  detailsForm : any = {};
  addPriceForm: FormGroup;

  constructor(
      private orderService: OrderService,
      private formBuilder: FormBuilder,
      private _location: Location,
      private dateAdapter: DateAdapter<Date>,
  ) {
    this.dateAdapter.setLocale('uk-UA');
    this.orderService.confirmEditCloseDate.subscribe(cnt => this.confirmId = cnt);
    {
      this.dateAdapter.getFirstDayOfWeek = () => 1;
    }
  }

  ngOnInit() {
    this.addPriceForm = this.formBuilder.group({
      date: ['', Validators.required],
      time: ['', Validators.required],
    });
  }

  close() {
    this.orderService.editDateClose(null);
    this.confirmId = null;
    this.detailsForm = {};
  }

  get f() { return this.addPriceForm.controls; }

  confirm() {
    this.detailsForm.date = moment(this.f.date.value).format('YYYY/MM/DD ' + this.f.time.value + ':00');
    this.detailsForm.id = this.confirmId;
    this.orderService.changeDateClose(this.detailsForm).subscribe(data => {
      this.close();
    });
  }

  onSubmit() {
    this.confirm();
  }




}
